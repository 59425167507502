import React, { useEffect, useRef } from 'react';
import 'src/styles/atoms/LockIcon.scss'
import { IoLockClosed, IoLockOpenOutline } from "react-icons/io5";
import { renderToStaticMarkup } from 'react-dom/server';

interface LockIconProps {
    locked: boolean;
    onClick: () => void;
}


export const LockIcon: React.FC<LockIconProps> = ({locked, onClick}) => {
    const lockIconRef = useRef<HTMLLabelElement>(null);

    useEffect(() => {
        const lockOpenSVG = `url("data:image/svg+xml,${encodeURIComponent(renderToStaticMarkup(<IoLockOpenOutline color="rgb(231,153,096)"/>))}")`;
        const lockCloseSVG = `url("data:image/svg+xml,${encodeURIComponent(renderToStaticMarkup(<IoLockClosed color="rgb(231,153,096)"/>))}")`;

        if (lockIconRef.current) {
            lockIconRef.current.style.setProperty('--lock-open-icon', lockOpenSVG);
            lockIconRef.current.style.setProperty('--lock-close-icon', lockCloseSVG);
        }
    }, []);
    return (
        <label className={`lock-icon ${locked ? 'locked' : 'unlocked'}`} ref={lockIconRef}>
            <input type="checkbox" checked={locked} onChange={onClick} />
            <span className="icon"></span>
        </label>
    );
};
