import modalCss from "src/styles/organisms/modal.module.scss";
interface ModalHowToUseProps {
    active: boolean;
    deactivateModal: () => void;
}

export const ModalHowToUse: React.FC<ModalHowToUseProps>  = ({active, deactivateModal}) => {
    return (
        <>
            <div className={`${modalCss.modal} ${active && modalCss.active}`} role="dialog">
                <h3>使い方</h3>
                <p className={modalCss.note}>もちものリストを共有するwebアプリです。<br/>個人情報などを記載しないでください。</p>
                <button className={modalCss.close} onClick={deactivateModal}>X</button>
                <div>
                    <h4>リストをつくる</h4>
                    <ol className={modalCss.order}>
                        <li className={modalCss.list}>リスト履歴からリスト名を入力して追加をタップ</li>
                        <li className={modalCss.list}>共有されたテンプレートURLを開く</li>
                    </ol>
                </div>
                <div>
                    <h4>リストを保存する</h4>
                    <ol className={modalCss.order}>
                        <li className={modalCss.list}>使用したリストの履歴はブラウザに保存されます</li>
                    </ol>
                    <p className={modalCss.note}>シークレットモードなどでは無効です。</p>
                </div>
                <div>
                    <h4>リストをつかう</h4>
                    <ol className={modalCss.order}>
                        <li className={modalCss.list}>ブックマークなどの保存したURLからリストを開く</li>
                        <li className={modalCss.list}>リストの履歴から開く</li>
                    </ol>
                </div>
                <div>
                    <h4>リストを共有する</h4>
                    <ol className={modalCss.order}>
                        <li className={modalCss.list}>リストのURLそのものを共有する。<br/>同じリストの編集と閲覧が可能です。</li>
                        <li className={modalCss.list}>共有専用URLを共有する。<br/>リストのなかみだけがコピーされます。</li>
                    </ol>
                    <p className={modalCss.note}>リストには個人情報などを記載しないでください。</p>
                </div>
            </div>
            <div className={`${modalCss.overlay} ${active && modalCss.active}`} onClick={deactivateModal}></div>
        </>
    )
}