import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from 'src/config/api';
import { LockIcon } from 'src/components/atoms/LockIcon';
import { Mochimono, CreateMochimonoParams, UpdateMochimonoParams } from "@/types/mochimoList";
import { FaCheck, FaPenAlt, FaShare } from "react-icons/fa";
import { Suitcase } from '@/types/suitcases';
import { Link } from 'react-router-dom';
import { FaSuitcase } from "react-icons/fa";

export const MochimoList: React.FC = () => {
  const { suitcaseCode } = useParams<{ suitcaseCode: string }>();
  const navigate = useNavigate();
  useEffect(() => {
    if (!suitcaseCode || typeof suitcaseCode !== 'string') {
      alert('アクセスできませんでした。');
      navigate('/suitcases');
    }
  }, [suitcaseCode, navigate]);

  //
  // 画面表示
  // -------------------------------------------------------------------------
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [isFocused, setIsFocused] = useState(false);
  const [activeButton, setActiveButton] = useState<'check' | 'edit' | 'share'>('check');
  const inputRef = useRef<HTMLInputElement>(null);
  let text = 'をテンプレートとして共有できます　鍵アイコンをタップして公開状態を切り替え　鍵のかかったアイコンは非公開　公開状態のアイテム';
  const handleButtonClick = (buttonName: 'check' | 'edit' | 'share') => {
    setActiveButton(buttonName);
    setEditMode(buttonName === 'edit');
  };

  const [currentIndex, setCurrentIndex] = useState(1);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (inputRef.current) {
        const displayText = text.slice(currentIndex) + text.slice(0, currentIndex);
        inputRef.current.placeholder = displayText;
        setCurrentIndex((currentIndex + 1) % text.length);
      }
    }, 300);

    return () => clearInterval(intervalId);
  }, [text, currentIndex]);

  const alertMessage = (error: any, defaultMessage: string) => {
    let message = error?.response?.data?.message;
    if (message || typeof message !== "string") {
      message = defaultMessage;
    }
    alert(message);
  }

  // -------------------------------------------------------------------------
  // データ操作・API通信
  // -------------------------------------------------------------------------

  // 登録のないもちものリストをスーツケース一覧に加える
  // -------------------------------------------------------------------------
  useCallback(() => {
    // TODO 汎用のsavelocaldataをつかう
    const localJsonData = localStorage.getItem('suitcases');
    const localData = localJsonData ? JSON.parse(localJsonData) : [];
    if (localData.includes(suitcaseCode) === false) {
      localData.push(suitcaseCode);
      localStorage.setItem('suitcases', JSON.stringify(localData));
    }
  },[suitcaseCode]);

  // スーツケースの取得
  // -------------------------------------------------------------------------
  const [suitcase, setSuitcase] = useState<Suitcase>();
  const fetchSuitcase = useCallback( async () => {
    api.get(`/suitcase/show/${suitcaseCode}`)
      .then(response => {
        setSuitcase(response.data.suitcase);
      })
      .catch(error => {
        alert('処理に失敗しました');
      }).finally(() => {
      });
  }, [suitcaseCode]);

  useEffect(() => {
    fetchSuitcase();
  }, [fetchSuitcase]);

  // もちもの取得
  // -------------------------------------------------------------------------
  const [items, setItems] = useState<Mochimono[] | []>([]);
  const fetchItems = useCallback( async () => {
    api.get(`/mochimono/${suitcaseCode}`)
      .then(response => {
        setItems(response.data.mochimonos);
      })
      .catch(error => {
        alert('処理に失敗しました');
      }).finally(() => {
        setLoading(false);
      });
  }, [suitcaseCode]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  // バリデーション
  // -------------------------------------------------------------------------
  const confirmDigitSave = (value: string): boolean => {
    const digitCount = (value.match(/\d/g) || []).length;
    if (digitCount >= 3) {
      return window.confirm('いくつかの数字が検知されました。\n個人情報などは入力しないでください。\nこのまま保存処理を続けますか？');
    }
    return true;
  }

  // 新規作成
  // -------------------------------------------------------------------------
  const addInputRef = useRef<HTMLInputElement>(null);
  const handleAddBlur = () => {
    activeButton === 'check' && setIsFocused(false);
  };

  const handleAddFocus = () => {
    activeButton === 'check' && setIsFocused(true);
  };

  const addMochimono = async () => {
    if (addInputRef.current && addInputRef.current.value !== '') {
      if(confirmDigitSave(addInputRef.current.value) === false) {
        return
      }
      const params: CreateMochimonoParams = {
        suitcase_code: suitcaseCode || '',
        name: addInputRef.current.value,
      };
      await api.post('/mochimono/store', params).then((response) => {
        setItems([...items, response.data.mochimono]);
      }).catch((error) => {
        alertMessage(error, 'もちものの新規作成に失敗しました。');
      });
      addInputRef.current.value = '';
    }
  };

  // 編集
  // -------------------------------------------------------------------------
  const [mochimoNameOnFocus, setMochimoNameOnFocus] = useState<string>('');
  const handleEditBlur = async (code: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const mochimoNameOnBlur = event.target.value;
    if (mochimoNameOnFocus !== mochimoNameOnBlur) {
      if(confirmDigitSave(mochimoNameOnBlur) === false) {
        event.target.value = mochimoNameOnFocus;
        return
      }
      await updateItem(code, mochimoNameOnBlur);
    }
    setMochimoNameOnFocus('');
  };
  const handleEditFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMochimoNameOnFocus(event.target.value);
  };

  const updateItem = async (code: string, editedName: string) => {
    const params: UpdateMochimonoParams = {
      code: code,
      name: editedName,
    };
    await api.patch('/mochimono/update', params).then((response) => {
      const newItems = items.map(item =>
        item.code === code ? { ...item, name: editedName } : item
      );
      setItems(newItems);
    }).catch((error) => {
      alertMessage(error, '編集内容の保存に失敗しました。')
    });
  };

  // チェック
  // -------------------------------------------------------------------------
  const handleCheckChange = async (event: React.ChangeEvent<HTMLInputElement>, code: string) => {
    const newCheckedState = event.target.checked;
    const params = {
      code: code,
      checked: newCheckedState
    };
    await api.patch('/check/update', params).then(response => {
      const newItems = items.map(item =>
        item.code === code ? { ...item, checked: newCheckedState } : item
      );
      setItems(newItems);
    }).catch(error => {
    }).finally(() => {
    });
  };

  // 公開・非公開
  // -------------------------------------------------------------------------
  const toggleShared = async (code: string, newShared: boolean) => {
    const params = {
      code: code,
      shared: newShared
    };
    await api.patch('/mochimono/update-shared', params).then(response => {
      const newItems = items.map(item =>
        item.code === code ? { ...item, shared: newShared } : item
      );
      setItems(newItems);
    }).catch(error => {
    }).finally(() => {
    });
  };

  const shareSuitcase = () => {
    const url = new URL(window.location.href);
    const domain = url.protocol + '//' + url.hostname;
    navigator.clipboard.writeText(`${domain}/replicate/${suitcaseCode}`)
      .then(() => {
        alert('複製用のurlをコピーしました。 ');
      }).catch((err) => {
      });
  }

  // -------------------------------------------------------------------------
  // JSX
  // -------------------------------------------------------------------------
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <header className="header">
        <div className="header-logo">
          <span className="header-logo-title">mochimo</span>
        </div>
        <Link to='/suitcases' className='suitcase-icon'>
          <FaSuitcase color="#fff"/>
        </Link>
      </header>
      <main>
        <div className="container">
          <h1 className="list-title">{suitcase?.name}</h1>
          <ul className="list-container">
            {items.map((item, index) => (
              <li key={item.code}>
                <label>
                  <div className="checkbox-label width100">
                    <input type="checkbox" checked={item.checked} disabled={activeButton !== 'check'} onChange={(event) => handleCheckChange(event, item.code)} />
                    <div className={`custom-checkbox ${activeButton !== 'check' && 'disabled'}`}></div>
                    {editMode ? (
                      <div className={`input-group`}>
                        <input
                          type="text"
                          className="input-item"
                          defaultValue={item.name}
                          onBlur={(event) => handleEditBlur(item.code, event)}
                          onFocus={(event) => handleEditFocus(event)}
                        />
                        <div className="underline"></div>
                      </div>
                    ) : (
                      <div className='item-name'>{item.name}</div>
                    )}
                  </div>
                  {activeButton === 'share' && (
                    <LockIcon locked={!item.shared} onClick={() => toggleShared(item.code, !item.shared)} />
                  )}
                </label>
              </li>
            ))}
          </ul>
        </div>
      </main>
      <footer>
        <div className='horizontaLine'></div>
        <div  className="footer">
          <div className={`function ${isFocused ? 'focus' : ''}`}>
            {isFocused ? (
              <>
                <button
                  className={`icon-button ${activeButton === 'check' ? 'active' : ''}`}
                  onClick={() => handleButtonClick('check')}
                >
                  <FaCheck  color={activeButton === 'check' ? '#fff' : 'rgb(249,194, 112)'} />
                </button>
              </>
            ) : (
              <>
                <button
                  className={`icon-button ${activeButton === 'check' ? 'active' : ''}`}
                  onClick={() => handleButtonClick('check')}
                >
                  <FaCheck  color={activeButton === 'check' ? '#fff' : 'rgb(249,194, 112)'} />
                </button>
                <button
                  className={`icon-button ${activeButton === 'edit' ? 'active' : ''}`}
                  onClick={() => handleButtonClick('edit')}
                >
                  <FaPenAlt color={activeButton === 'edit' ? '#fff' : 'rgb(249,194, 112)'} />
                </button>
                <button
                  className={`icon-button ${activeButton === 'share' ? 'active' : ''}`}
                  onClick={() => handleButtonClick('share')}
                >
                  <FaShare color={activeButton === 'share' ? '#fff' : 'rgb(249,194, 112)'}/>
                </button>
              </>
            )}
          </div>
          <div className="interface">
            <div className={`input-group ${activeButton !== 'check' ? 'hide' : ''}`}>
              <input
                type="text"
                className="input-item"
                onBlur={handleAddBlur}
                onFocus={handleAddFocus}
                ref={addInputRef}
              />
              <div className="underline"></div>
            </div>
            <div className={`input-group ${activeButton !== 'edit' ? 'hide' : ''}`}>
              <input
                type="text"
                className="input-item"
                placeholder='もちもの編集'
                readOnly
              />
              <div className="underline"></div>
            </div>
            <div className={`input-group ${activeButton !== 'share' ? 'hide' : ''}`}>
              <input
                type="text"
                className="input-item"
                readOnly
                ref={inputRef}
              />
              <div className="underline"></div>
            </div>
          </div>
          <div className="exec">
            <button className={`exec-btn ${activeButton !== 'check' ? 'hide' : ''}`} onClick={addMochimono}><span className="text12 color-white">追加</span></button>
            <button className={`exec-btn ${activeButton !== 'share' ? 'hide' : ''}`} onClick={shareSuitcase}><span className="text12 color-white">共有</span></button>
          </div>
        </div>
      </footer>
    </>
  );
};
