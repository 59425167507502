import React from 'react';
import modalCss from "src/styles/organisms/modal.module.scss";
import modalSelectImage from "src/styles/organisms/modal-select-image.module.scss";

interface ModalSelectImageProps {
    active: boolean;
    deactivateModal: () => void;
    onSelectImage: (url: string) => void;
}

export const ModalSelectImage: React.FC<ModalSelectImageProps> = ({ active, deactivateModal, onSelectImage }) => {
    const images = [
        "/images/suitcase/simon-maage-C9dhUVP-o6w-unsplash.jpg",
        "/images/suitcase/thought-catalog-9aOswReDKPo-unsplash.jpg",
        "/images/suitcase/ernest-porzi-Z-Y6I45f9kQ-unsplash.jpg",
        "/images/suitcase/japan-4540612_640.jpg",
        "/images/suitcase/joseph-gonzalez-QaGDmf5tMiE-unsplash.jpg",
        "/images/suitcase/karly-gomez-lK1Q5RyD6tc-unsplash.jpg",
        "/images/suitcase/mae-mu-9z-veIxii6k-unsplash.jpg",
    ];

    return (
        <>
            <div className={`${modalCss.modal} ${active && modalCss.active}`} role="dialog">
                <h3>画像選択</h3>
                <button className={modalCss.close} onClick={deactivateModal}>X</button>
                <div>
                    <h4>画像を選択してください</h4>
                    <div className={modalSelectImage.gridContainer}>
                        {images.map((image, index) => (
                            <div
                                key={index}
                                className={modalSelectImage.gridItem}
                                onClick={() => onSelectImage(image)}
                            >
                                <img src={image} alt={`画像 ${index + 1}`} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={`${modalCss.overlay} ${active && modalCss.active}`} onClick={deactivateModal}></div>
        </>
    );
};
