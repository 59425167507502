import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Suitcases } from "src/components/pages/Suitcases";
import { Home } from 'src/components/pages/Home';
import { Replicate } from 'src/components/pages/Replicate';
import 'src/styles/common.scss'
import { MochimoList } from 'src/components/pages/MochimoList';

function App() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/suitcases" element={<Suitcases />} />
          <Route path="/mochimo-list/:suitcaseCode" element={<MochimoList />} />
          <Route path="/replicate/:suitcaseCode" element={<Replicate />} />
        </Routes>
      </BrowserRouter>
    );
}

export default App;
