import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'src/config/api';
import suitcaseCSS from 'src/styles/suitcases.module.scss'
import { IoMdHelp } from "react-icons/io";
import { FaPenAlt, FaSuitcaseRolling } from "react-icons/fa";
import noImage from 'src/images/brett-jordan-P_LEitbpCPg-unsplash.jpg';
import { Suitcase } from "src/types/suitcases";
import { saveLocalData } from "src/util/local";
import { ModalHowToUse } from 'src/components/organisms/ModalHowToUse';
import { ModalSelectImage } from 'src/components/organisms/ModalSelectImage';
import { IconContext } from "react-icons";

export const Suitcases: React.FC = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [activeButton, setActiveButton] = useState<'check' | 'edit' | 'share'>('check');
  const [editMode, setEditMode] = useState<boolean>(false);

  //
  // 画面表示
  // -------------------------------------------------------------------------
  const handleButtonClick = (buttonName: 'check' | 'edit' | 'share') => {
    if (buttonName === `share`) {
      setModalHowToUseActive(true);
    } else {
      setModalHowToUseActive(false);
    }
    setActiveButton(buttonName);
    setEditMode(buttonName === 'edit');
  };

  // 使い方
  // -------------------------------------------------------------------------
  const [modalHowToUseActive, setModalHowToUseActive] = useState<boolean>(false);
  const activateModal = () => {
    setModalHowToUseActive(true);
  }

  const deactivateModalHowToUse = () => {
    setModalHowToUseActive(false);
  }

  // 流れる補助テキスト
  // -------------------------------------------------------------------------
  const inputRef = useRef<HTMLInputElement>(null);
  let text = 'してください。　名前、画像を変更できます。　画像はタップ後に画像を選択';
  const [currentIndex, setCurrentIndex] = useState(1);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (inputRef.current) {
        const displayText = text.slice(currentIndex) + text.slice(0, currentIndex);
        inputRef.current.placeholder = displayText;
        setCurrentIndex((currentIndex + 1) % text.length);
      }
    }, 300);

    return () => clearInterval(intervalId);
  }, [text, currentIndex]);

  const alertMessage = (error: any, defaultMessage: string) => {
    let message = error?.response?.data?.message;
    if (message || typeof message !== "string") {
      message = defaultMessage;
    }
  }

  // 画面遷移
  // -------------------------------------------------------------------------
  const navigate = useNavigate();
  const handleListItemClick = (suitcaseCode: string) => {
    if (editMode === false) {
      navigate(`/mochimo-list/${suitcaseCode}`);
    }
  };

  // 読込み
  // -------------------------------------------------------------------------
  const [suitcases, setSuitcases] = useState<Suitcase[] | []>([]);
  const fetchSuitcases = async (localSuitcaseCodeData: string[]) => {
    const params = {
      code_list: localSuitcaseCodeData,
    };
    api.post(`/suitcase`, params)
      .then(response => {
        const suitcases_data = response.data.suitcases;
        setSuitcases(suitcases_data);
      })
      .catch(error => {
        alert('スーツケース一覧の取得に失敗しました');
      })
      .finally(() => {
      });
  };

  useEffect(() => {
    const localSuitcaseCodeDataJson = localStorage.getItem('suitcases');
    const localSuitcaseCodeData = localSuitcaseCodeDataJson ? JSON.parse(localSuitcaseCodeDataJson) : [];
    if (localSuitcaseCodeData.length > 0) {
      fetchSuitcases(localSuitcaseCodeData);
    } else {
      activateModal()
    }
  }, []);

  // 新規作成
  // -------------------------------------------------------------------------
  const addInputRef = useRef<HTMLInputElement>(null);
  const handleAddBlur = () => {
    activeButton === 'check' && setIsFocused(false);
  };

  const handleAddFocus = () => {
    activeButton === 'check' && setIsFocused(true);
  };

  const addSuitcase = async (): Promise<void> => {
    if (addInputRef.current && addInputRef.current.value === '') {
      alert('リスト名を入力してください。');
      return;
    }
    if (addInputRef.current && addInputRef.current.value !== '') {
      const params = {
        name: addInputRef.current.value,
      };
      await api.post('/suitcase/store', params).then((response) => {
        const newSuitcase = response?.data?.suitcase
        if (newSuitcase) {
          setSuitcases([response.data.suitcase, ...suitcases]);
          saveLocalData(newSuitcase.code)
        }
      }).catch((error) => {
        alertMessage(error, 'リストの新規作成に失敗しました。');
      });
      addInputRef.current.value = '';
    }
  };

  // 画像選択
  // -------------------------------------------------------------------------
  const [selectedImageSuitcaseCode, setSelectedImageSuitcaseCode] = useState<string>('');
  const [modalSelectImageActive, setModalSelectImageActive] = useState<boolean>(false);
  const selectImage = (suitcaseCode: string) => {
    if (editMode === false) {
      return;
    }
    setSelectedImageSuitcaseCode(suitcaseCode);
    activateModalSelectImage();
  }

  const activateModalSelectImage = () => {
    setModalSelectImageActive(true);
  }

  const deactivateModalSelectImage = () => {
    setModalSelectImageActive(false);
  }

  const handleSelectImage = async (url: string) => {
    const confirmResult = window.confirm('画像を変更します');
    if (confirmResult === false) {
      return;
    }
    const params = {
      code: selectedImageSuitcaseCode,
      image: url
    };
    await api.patch('/suitcase/update-image', params)
      .then(response => {
        const newSuitcases = suitcases.map(Suitcase =>
          Suitcase.code === selectedImageSuitcaseCode ? { ...Suitcase, image: url } : Suitcase
        );
        setSuitcases(newSuitcases);
      })
      .catch(error => {
      })
      .finally(() => {
        deactivateModalSelectImage();
      });
  }

  // 名前変更
  // -------------------------------------------------------------------------
  let nameOnFocus: string;
  const handleEditBlur = async (code: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const nameOnBlur = event.target.value;
    if (nameOnFocus !== nameOnBlur) {
      await updateSuitcase(code, nameOnBlur);
    }
    nameOnFocus = '';
  };
  const handleEditFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    nameOnFocus = event.target.value;
  };

  const updateSuitcase = async (code: string, editedName: string) => {
    const params = {
      code: code,
      name: editedName,
    };
    await api.patch('/suitcase/update-name', params)
      .then((response) => {
        const newSuitcase = suitcases.map(suitcase =>
          suitcase.code === code ? { ...suitcase, name: editedName } : suitcase
        );
        setSuitcases(newSuitcase);
      }).catch((error) => {
        alertMessage(error, '編集内容の保存に失敗しました。')
      });
  };

  return (
    <>
      <header className="header">
        <div className="header-logo">
          <span className="header-logo-title">mochimo</span>
        </div>
      </header>
      <main>
        <div className={suitcaseCSS.container}>
          <h1 className={suitcaseCSS.title}>リスト履歴</h1>
          <ul className={suitcaseCSS.item}>
            {suitcases.map((suitcase, index) =>
              <li key={suitcase.code} className={suitcaseCSS.list} onClick={() => handleListItemClick(suitcase.code)}>
                {suitcase.image ? (
                  <div className={suitcaseCSS.image} onClick={() => selectImage(suitcase.code)}>
                    <img src={suitcase.image} alt={`${suitcase.name}画像`} />
                  </div>
                ) : (
                  <div className={`${suitcaseCSS.image} ${suitcaseCSS.noImage}`} onClick={() => selectImage(suitcase.code)}>
                    <img src={noImage} alt={`${suitcase.name}画像`} />
                    <p>No Image</p>
                  </div>
                )}
                <div className={suitcaseCSS.content}>
                  <p className={suitcaseCSS.date}>
                    <time>{suitcase.date}</time>
                  </p>
                  {!editMode ? (
                    <h3 className={suitcaseCSS.title}>{suitcase.name}</h3>
                  ) : (
                    <input
                      type="text"
                      className={suitcaseCSS.inputItem}
                      defaultValue={suitcase.name}
                      onBlur={(event) => handleEditBlur(suitcase.code, event)}
                      onFocus={(event) => handleEditFocus(event)}
                    />
                  )}
                </div>
              </li>
            )}
          </ul>
        </div>
      </main>
      <footer className="footer">
        <div className={`function ${isFocused ? 'focus' : ''}`}>
          {isFocused ? (
            <>
              <button
                className={`icon-button ${activeButton === 'check' ? 'active' : ''}`}
                onClick={() => handleButtonClick('check')}
              >
                <IconContext.Provider value={{ className: suitcaseCSS.reactIcons }}>
                  <FaSuitcaseRolling />
                </IconContext.Provider>
              </button>
            </>
          ) : (
            <>
              <button
                className={`icon-button ${activeButton === 'check' ? 'active' : ''}`}
                onClick={() => handleButtonClick('check')}
              >
                <FaSuitcaseRolling color={activeButton === 'check' ? '#fff' : 'rgb(249,194, 112)'}/>
              </button>
              <button
                className={`icon-button ${activeButton === 'edit' ? 'active' : ''}`}
                onClick={() => handleButtonClick('edit')}
              >
                <FaPenAlt color={activeButton === 'edit' ? '#fff' : 'rgb(249,194, 112)'}/>
              </button>
              <button
                className={`icon-button ${activeButton === 'share' ? 'active' : ''}`}
                onClick={() => handleButtonClick('share')}
              >
                <IoMdHelp color={activeButton === 'share' ? '#fff' : 'rgb(249,194, 112)'}/>
              </button>
            </>
          )}
        </div>
        <div className="interface">
          <div className={`input-group ${activeButton !== 'check' ? 'hide' : ''}`}>
            <input
              type="text"
              className="input-item"
              onBlur={handleAddBlur}
              onFocus={handleAddFocus}
              ref={addInputRef}
            />
            <div className="underline"></div>
          </div>
          <div className={`input-group ${activeButton !== 'edit' ? 'hide' : ''}`}>
            <input
              type="text"
              className="input-item"
              placeholder='名前や画像を変更できます'
              readOnly
              ref={inputRef}
            />
            <div className="underline"></div>
          </div>
          <div className={`input-group ${activeButton !== 'share' ? 'hide' : ''}`}>
            <input
              type="text"
              className="input-item"
              readOnly
            />
            <div className="underline"></div>
          </div>
        </div>
          <div className="exec">
            <button className={`exec-btn`} onClick={addSuitcase} ><span className="text12 color-white">ついか</span></button>
          </div>
      </footer>
      <ModalHowToUse active={modalHowToUseActive} deactivateModal={deactivateModalHowToUse} />
      <ModalSelectImage active={modalSelectImageActive} deactivateModal={deactivateModalSelectImage} onSelectImage={(url) => handleSelectImage(url)} />
    </>
  );
};
