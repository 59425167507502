import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import modalCss from "src/styles/organisms/modal.module.scss";
import api from 'src/config/api';

export const Replicate: React.FC = () => {
  const { suitcaseCode } = useParams<{ suitcaseCode: string }>();
  const navigate = useNavigate();
  useEffect(() => {
    if (!suitcaseCode || typeof suitcaseCode !== 'string') {
      alert('アクセスできませんでした。');
      navigate('/suitcases');
    }
  }, [suitcaseCode, navigate]);
  const [buttonName, setButtonName] = useState("OK");
  const modalRef = useRef<HTMLDivElement | null>(null);
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const activateModal = () => {
    modalRef.current && modalRef.current.classList.add(modalCss.active);
    overlayRef.current && overlayRef.current.classList.add(modalCss.active);
  }

  const inputRef = useRef<HTMLInputElement>(null);

  const replicate = () => {
    if (inputRef.current && inputRef.current.value !== '') {
      setButtonName('処理中...')
      const params = {
        code: suitcaseCode,
        name: inputRef.current.value,
      };
      api.post(`/suitcase/replicate`, params)
        .then(response => {
          const suitCaseCode = response?.data?.suitcase?.code;
          if (suitCaseCode) {
            navigate(`/mochimo-list/${suitCaseCode}`);
          } else {
            alert('複製したリストの取得に失敗しました。');
          }
        })
        .catch(error => {
          alert('複製に失敗しました');
        })
        .finally(() => {
          setButtonName('OK')
        });
    } else {
      alert('リストの名前を入力してください。')
    }
  }

  useEffect(() => {
    activateModal();
  }, []);

  return (
    <>
      <header className="header">
        <div className="header-logo">
          <span className="header-logo-title">mochimo</span>
        </div>
      </header>
      <main>
      </main>
      <div ref={modalRef} className={`${modalCss.modal}`} role="dialog">
        <div>
          <button className={modalCss.close}>X</button>
          <h4>もちものリストを複製</h4>
          <p className={modalCss.small}>テンプレートからもちものリストを複製します</p>
          <div className={modalCss.containerInput}>
            <input
              placeholder='任意のリスト名を入力'
              type="text"
              className={modalCss.nameInput}
              ref={inputRef}
            />
          </div>
          <div className={modalCss.containerExec}>
            <button className={modalCss.exec} onClick={replicate}>{buttonName}</button>
          </div>
        </div>
      </div>
      <div ref={overlayRef} className={`${modalCss.overlay}`}></div>
    </>
  );
};
