import { Link } from 'react-router-dom';
import 'src/styles/Home.scss'

export const Home: React.FC = () => {
  return (
    <>
      <header className="header">
        <div className="header-logo">
          <span className="header-logo-title">mochimo</span>
        </div>
      </header>
      <main>
        <div className="container">
          <h1 className="list-title">Mochimo</h1>
          <p className="description">もちものリストのウェブアプリ</p>
          <ul className="list-container">
            <li></li>
          </ul>
        </div>
      </main>
      <footer className="footer">
        <div className="exec">
          <Link className={`exec-btn`} to='/suitcases'><span className="text12 color-white">使う</span></Link>
        </div>
      </footer>
    </>
  );
};
